import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";

//Styles
import "./Navbar.css";

//Context
import { NavbarContext } from "../../context/NavbarContext";

const Navbar = () => {

  const [viewMenu, setViewMenu] = useState(false);

  const { propDevsInView, energyInView, roadInView, industrialInView } =
    useContext(NavbarContext);

  return (
    <nav className="nb-container">
      <Link to="/" className="nb-logo-container">
        <img
          src={
            roadInView
              ? "https://pagina-otacc.s3.amazonaws.com/pages/otacc-page/assets/icons/logo-otacc-white.webp"
              : "https://pagina-otacc.s3.amazonaws.com/pages/otacc-page/assets/icons/logo-otacc.webp"
          }
          alt="Logo Otacc"
          className="nb-logo-img"
        />
      </Link>
      <div className="nb-menus-container-nm">
        <a
          href="#property"
          className={`nb-option ${propDevsInView ? "current" : ""}`}
        >
          Vivienda
        </a>
        <a
          href="#industrial"
          className={`nb-option ${industrialInView ? "current" : ""}`}
        >
          Oil & Gas
        </a>
        <a
          href="#energy"
          className={`nb-option ${energyInView ? "current" : ""}`}
        >
          Energías Renovables
        </a>
        <a href="#road" className={`nb-option ${roadInView ? "current" : ""}`}>
          Infraestructura
        </a>
        <Link to="/nosotros" className="nb-option">
          Nosotros
        </Link>
      </div>
      <div className="nb-menus-mobile-container">
        <img onClick={() => setViewMenu(!viewMenu)} src="https://pagina-otacc.s3.amazonaws.com/pages/otacc-page/assets/ham-menu-icon.webp" alt="" className="ham-icon" />
        <div className={`mb-menus-container ${viewMenu ? '' : 'closed-menu'}`}>
          <a
            href="#property"
            className="nb-option"
            onClick={() => setViewMenu(false)}
          >
            Vivienda
          </a>
          <a
            href="#industrial"
            className="nb-option"
            onClick={() => setViewMenu(false)}
          >
            Oil & Gas
          </a>
          <a
            href="#energy"
            className="nb-option"
            onClick={() => setViewMenu(false)}
          >
            Energías Renovables
          </a>
          <a
            href="#road"
            className="nb-option"
            onClick={() => setViewMenu(false)}
          >
            Infraestructura
          </a>
          <Link
            to="/nosotros"
            className="nb-option"
          >
            Nosotros
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

import React from "react";

//Style
import "./InfoEnergySection.css";

const InfoEnergySection = ({ info }) => {
  return (
    <section className="info-ene-section">
      <div className="title-container">
        <span className="deco-title"></span>
        <h1 className="ene-title">Energía Renovable</h1>
        <img
          style={{ width: "1.5rem" }}
          className="title-icon"
          src="https://pagina-otacc.s3.amazonaws.com/pages/otacc-page/assets/icons/title-icon-3.webp"
          alt=""
        />
      </div>
      <div className="info-ene-supercontainer">
        <div className="info-ene-container">
          <div className="info-ene-txt-container">
            <p className="ene-subtitle">
              Desarrollo, Construcción (EPC) y O&M:
            </p>
            <ul>
              <li>Parques Fotovoltaicos</li>
              <li>Techos Solares</li>
              <li>Parques Eólicos</li>
              <li>Hidroeléctricas</li>
              <li>Biomasa</li>
              <li>Redes Eléctricas</li>
              <li>Subestaciones Eléctricas</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InfoEnergySection;

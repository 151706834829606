import React from "react";

//Style
import "./GoalsAboutUsSection.css";

//Data
import dataInfoGoalsFH from "../../json/infoAboutUs/infoGoalsFH.json";
import dataInfoGoalsSH from "../../json/infoAboutUs/infoGoalsSH.json";

const GoalsAboutUsSection = () => {
  return (
    <section className="goals-section">
      <div className="half-section-container first">
        <div className="goals-info-subcontainer">
          <div className="info-vision-container">
            <div className="title-container vision-title-pos">
              <span className="deco-title deco-oposite"></span>
              <h2 className="title">VISIÓN</h2>
            </div>
            <h4>
              Ser una empresa...
            </h4>
            {dataInfoGoalsFH.dataVision.map((info, i) => {
              return (
                <div className="vision-elm-container" key={i}>
                  <img
                    style={{ width: "1.2rem", height: "1.2rem" }}
                    src="https://pagina-otacc.s3.amazonaws.com/pages/otacc-page/aboutus/assets/check-icon.webp"
                    alt=""
                  />
                  <h4>{info.text}</h4>
                </div>
              );
            })}
          </div>
          <span className="separation-line"></span>
          <div className="goals-info-subcontainer">
            <div className="info-strategies-container">
              <div className="title-container">
                <span className="deco-title deco-oposite"></span>
                <h2 className="title">PILARES ESTRATEGICOS</h2>
              </div>
              {dataInfoGoalsFH.dataEstrategies.map((info, i) => {
                return (
                  <div className="info-estrategy-container" key={i}>
                    <div className="icons-container">
                      <img style={{width: "5rem"}} src={info.numberIcon} alt="" />
                      <img className="estrategy-icon" src={info.icon} alt="" />
                    </div>
                    <h4>{info.text}</h4>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="half-section-container second">
        <div className="goals-title-container">
          <div className="title-container">
            <span className="deco-title" />
            <h1>NUESTROS DIFERENCIADORES</h1>
          </div>
        </div>
        <div className="info-first-half-container">
          <div className="info-fh-elm-container">
            <div className="num-container">
              <img
                className="num-icon"
                src={dataInfoGoalsSH.data[0].number}
                alt=""
              />
            </div>
            <div className="text-info-elm-container">
              <div className="title-fh-elm-container">
                <h2>{dataInfoGoalsSH.data[0].title}</h2>
                <img
                  className="title-icon"
                  src={dataInfoGoalsSH.data[0].icon}
                  alt=""
                />
              </div>
              <div className="desc-info-container">
                {dataInfoGoalsSH.data[0].content}
              </div>
            </div>
          </div>
          <div className="info-fh-elm-container">
            <div className="num-container">
              <img
                className="num-icon"
                src={dataInfoGoalsSH.data[1].number}
                alt=""
              />
            </div>
            <div className="text-info-elm-container">
              <div className="title-fh-elm-container">
                <h2>{dataInfoGoalsSH.data[1].title}</h2>
                <img
                  className="title-icon"
                  src={dataInfoGoalsSH.data[1].icon}
                  alt=""
                />
              </div>
              <div className="desc-info-container">
                {dataInfoGoalsSH.data[1].content}
              </div>
            </div>
          </div>
          <div className="info-fh-elm-container">
            <div className="num-container">
              <img
                className="num-icon"
                src={dataInfoGoalsSH.data[2].number}
                alt=""
              />
            </div>
            <div className="text-info-elm-container">
              <div className="title-fh-elm-container">
                <h2>{dataInfoGoalsSH.data[2].title}</h2>
                <img
                  className="title-icon"
                  src={dataInfoGoalsSH.data[2].icon}
                  alt=""
                />
              </div>
              <div className="desc-info-container">
                {dataInfoGoalsSH.data[2].content}
              </div>
            </div>
          </div>
        </div>
        <div className="info-first-half-container">
          <div className="info-fh-elm-container">
            <div className="num-container">
              <img
                className="num-icon"
                src={dataInfoGoalsSH.data[3].number}
                alt=""
              />
            </div>
            <div className="text-info-elm-container">
              <div className="title-fh-elm-container">
                <h2>{dataInfoGoalsSH.data[3].title}</h2>
                <img
                  className="title-icon"
                  src={dataInfoGoalsSH.data[3].icon}
                  alt=""
                />
              </div>
              <div className="desc-info-container">
                {dataInfoGoalsSH.data[3].content}
              </div>
            </div>
          </div>
          <div className="info-fh-elm-container">
            <div className="num-container">
              <img
                className="num-icon"
                src={dataInfoGoalsSH.data[4].number}
                alt=""
              />
            </div>
            <div className="text-info-elm-container">
              <div className="title-fh-elm-container">
                <h2>{dataInfoGoalsSH.data[4].title}</h2>
                <img
                  className="title-icon"
                  src={dataInfoGoalsSH.data[4].icon}
                  alt=""
                />
              </div>
              <div className="desc-info-container">
                {dataInfoGoalsSH.data[4].content}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GoalsAboutUsSection;

import React from "react";

//Style
import "./InfoAboutUsSection.css";

//Data
import dataInfoWAWFH from "../../json/infoAboutUs/infoWAWFH.json";
import dataInfoWAWSH from "../../json/infoAboutUs/infoWAWSH.json";

const InfoAboutUsSection = () => {
  return (
    <section className="waw-section">
      <div className="half-section-container first">
        <div className="waw-title-container">
          <span className="deco-title" />
          <h1>LO QUE HACEMOS</h1>
        </div>
        <div className="info-first-half-container">
          <div className="info-fh-elm-container">
            <div className="num-container">
              <img
                className="num-icon"
                src={dataInfoWAWFH.data[0].number}
                alt=""
              />
            </div>
            <div className="text-info-elm-container">
              <div className="title-fh-elm-container">
                <h2>{dataInfoWAWFH.data[0].title}</h2>
                <img
                  className="title-icon"
                  src={dataInfoWAWFH.data[0].icon}
                  alt=""
                />
              </div>
              <div className="desc-info-container">
                {dataInfoWAWFH.data[0].content}
              </div>
            </div>
          </div>
          <div className="info-fh-elm-container">
            <div className="num-container">
              <img
                className="num-icon"
                src={dataInfoWAWFH.data[1].number}
                alt=""
              />
            </div>
            <div className="text-info-elm-container">
              <div className="title-fh-elm-container">
                <h2>{dataInfoWAWFH.data[1].title}</h2>
                <img
                  className="title-icon"
                  src={dataInfoWAWFH.data[1].icon}
                  alt=""
                />
              </div>
              <div className="desc-info-container">
                {dataInfoWAWFH.data[1].content}
              </div>
            </div>
          </div>
          <div className="info-fh-elm-container">
            <div className="num-container">
              <img
                className="num-icon"
                src={dataInfoWAWFH.data[2].number}
                alt=""
              />
            </div>
            <div className="text-info-elm-container">
              <div className="title-fh-elm-container">
                <h2>{dataInfoWAWFH.data[2].title}</h2>
                <img
                  className="title-icon"
                  src={dataInfoWAWFH.data[2].icon}
                  alt=""
                />
              </div>
              <div className="desc-info-container">
                {dataInfoWAWFH.data[2].content}
              </div>
            </div>
          </div>
        </div>
        <div className="info-first-half-container">
          <div className="info-fh-elm-container">
            <div className="num-container">
              <img
                className="num-icon"
                src={dataInfoWAWFH.data[3].number}
                alt=""
              />
            </div>
            <div className="text-info-elm-container">
              <div className="title-fh-elm-container">
                <h2>{dataInfoWAWFH.data[3].title}</h2>
                <img
                  className="title-icon"
                  src={dataInfoWAWFH.data[3].icon}
                  alt=""
                />
              </div>
              <div className="desc-info-container">
                {dataInfoWAWFH.data[3].content}
              </div>
            </div>
          </div>
          <div className="info-fh-elm-container">
            <div className="num-container">
              <img
                className="num-icon"
                src={dataInfoWAWFH.data[4].number}
                alt=""
              />
            </div>
            <div className="text-info-elm-container">
              <div className="title-fh-elm-container">
                <h2>{dataInfoWAWFH.data[4].title}</h2>
                <img
                  className="title-icon"
                  src={dataInfoWAWFH.data[4].icon}
                  alt=""
                />
              </div>
              <div className="desc-info-container">
                {dataInfoWAWFH.data[4].content}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="half-section-container second">
        <div className="waw-title-container second-waw-title">
          <span className="deco-title deco-oposite" />
          <h1>PROPOSITO</h1>
        </div>
        <div className="waw-sh-info-container">
          <div className="subtitle-waw-sh">
            Estructurar y ejecutar grandes proyectos de ingeniería y
            construcción que...
          </div>
          <div className="info-second-half-container">
            {dataInfoWAWSH.data.map((infoSH, i) => {
              return (
                <div key={i} className="info-sh-subcontainer">
                  <img className="info-sh-icon" src={infoSH.srcImg} alt="" />
                  <div>{infoSH.info}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default InfoAboutUsSection;

import React, { useContext } from "react";
import { Link } from "react-router-dom";

//Styles
import './IndustrialSection.css';

//Context
import { NavbarContext } from "../../context/NavbarContext";

const IndustrialSection = () => {

  const {industrial} = useContext(NavbarContext);

  return (
    <section ref={industrial} className="industrial-section-container" id="industrial">
      <img
        className="industrial-bg"
        src="https://pagina-otacc.s3.amazonaws.com/pages/otacc-page/assets/home-bgs/home-bg-4.webp"
        alt="Imagen de fondo de la sección de proyects industriales."
      />
      <div className="industrial-btn-container">
        <Link to="/oil-and-gas" className="industrial-btn" title="Plantas industriales">
          Ver nuestros proyectos
        </Link>
      </div>
    </section>
  );
};

export default IndustrialSection;

import React, { useContext } from "react";

//Styles
import './RoadSection.css';

//Context
import { NavbarContext } from "../../context/NavbarContext";
import { Link } from "react-router-dom";

const RoadSection = () => {

  const {road} = useContext(NavbarContext);

  return (
    <section ref={road} className="road-section-container" id="road">
      <img
        className="road-bg"
        src="https://pagina-otacc.s3.amazonaws.com/pages/otacc-page/assets/home-bgs/home-bg-3.webp"
        alt="Imagen de fondo de la sección de proyectos viales."
      />
      <div className="road-btn-container">
        <Link to="/infraestructura" className="road-btn" title="Infraestructura vial">
          Ver nuestros proyectos
        </Link>
      </div>
    </section>
  );
};

export default RoadSection;

import React from "react";

//Components
import Body from "../../components/Body/Body";
import Navbar from "../../components/Navbar/Navbar";

const Home = () => {
  return (
    <>
      <Navbar />
      <Body />
    </>
  );
};

export default Home;

import React from 'react'

//Components
import NavbarPropDevsSection from '../../components/NavbarPropDevsSection/NavbarPropDevsSection'
import FooterPropDevs from '../../components/FooterPropDevs/FooterPropDevs'
import BodyOilAndGas from '../../components/BodyOilAndGas/BodyOilAndGas'

const IndustrialScreen = () => {
  return (
    <>
      <NavbarPropDevsSection />
      <BodyOilAndGas />
      <FooterPropDevs />
    </>
  )
}

export default IndustrialScreen
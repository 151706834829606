import React, { useRef, useState } from "react";
import CarouselDos from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";
import Carousel from "react-grid-carousel";

//Styles
import "./PDProjectSection.css";

const PDProjectSection = ({ location, projects }) => {
  const gallery = useRef();

  const [datos, setDatos] = useState([]);

  const [classGallery, setClassGallery] = useState(false);

  const handleOpenGallery = () => {
    setClassGallery(true);
    gallery.current.maximize();
  };

  const handleCloseGallery = () => {
    setClassGallery(false);
    gallery.current.minimize();
  };

  return (
    <section className="projects-section">
      <div
        className={`title-section-container ${
          location === "/renovables" ||
          location === "/oil-and-gas" ||
          location === "/infraestructura"
            ? "show-title"
            : ""
        }`}
      >
        <h1 className="section-title">Proyectos</h1>
        <hr style={{ width: "50%" }} />
      </div>
      <div className={`prjs-gallery ${classGallery ? "open-gallery" : ""}`}>
        <div className="close-gallery" onClick={handleCloseGallery}></div>
        <CarouselDos
          ref={gallery}
          images={datos}
          style={{
            height: "100%",
            width: "100%",
            backgroundColor: "transparent",
          }}
          shouldLazyLoad={true}
          hasTransition={true}
          hasLeftButton={false}
          hasRightButton={false}
          hasIndexBoard={false}
          hasMediaButton={false}
          shouldMaximizeOnClick={false}
          hasSizeButton={false}
          hasLeftButtonAtMax={"centerLeft"}
          hasRightButtonAtMax={"centerRight"}
          hasSizeButtonAtMax={"topRight"}
          shouldSwipeOnMouse={true}
          objectFit={"contain"}
          hasThumbnails={false}
          hasThumbnailsAtMax={true}
          hasCaptionsAtMax={"bottom"}
        />
      </div>
      <div
        className={`carousel-no-mobile ${
          location === "/vivienda" ? "hide-carousel-pd" : ""
        }`}
      >
        <Carousel
          cols={4}
          rows={1}
          loop
          showDots={false}
          mobileBreakpoint={479}
        >
          {projects.data.map((project, i) => {
            return (
              <Carousel.Item key={i}>
                <div className="project-container">
                  <div className="hover-view-container">
                    <h4 className="hv-title">{project.info}</h4>
                    <p className="subtitle-info">{project.infoSubtitle}</p>
                    <div className="show-more-btn-container">
                      <hr />
                      <button
                        title="Ver Galería"
                        className="show-gallery-btn"
                        onClick={() => {
                          setDatos(project.photos);
                          handleOpenGallery();
                        }}
                      >
                        +
                      </button>
                    </div>
                    <h2 className="hv-title sub">{project.name}</h2>
                  </div>

                  <img
                    className="project-thumbnail"
                    src={project.thumbnail}
                    alt={project.name}
                  />
                </div>
              </Carousel.Item>
            );
          })}
        </Carousel>
      </div>
      <div
        className={`grid-prjs-container ${
          location === "/vivienda" ? "show-grid-pd" : ""
        }`}
      >
        {projects.data.map((project, i) => {
          if (
            location === "/renovables" ||
            location === "/oil-and-gas" ||
            location === "/infraestructura"
          ) {
            return (
              <div className="project-container" key={i}>
                <div className="hover-view-container">
                  <h4 className="hv-title">{project.info}</h4>
                  <p className="subtitle-info">{project.infoSubtitle}</p>
                  <div className="show-more-btn-container">
                    <hr />
                    <button
                      title="Ver Galería"
                      className="show-gallery-btn"
                      onClick={() => {
                        setDatos(project.photos);
                        handleOpenGallery();
                      }}
                    >
                      +
                    </button>
                  </div>
                  <h2 className="hv-title sub">{project.name}</h2>
                </div>

                <img
                  className="project-thumbnail"
                  src={project.thumbnail}
                  alt={project.name}
                />
              </div>
            );
          } else {
            return (
              <a
                className="project-container"
                key={i}
                href={project.url}
                title={project.title}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="project-thumbnail prop-section"
                  src={project.thumbnail}
                  alt={project.name}
                />
              </a>
            );
          }
        })}
      </div>
    </section>
  );
};

export default PDProjectSection;

import React, { useContext } from "react";
import { Link } from "react-router-dom";

//Styles
import "./PropDevsSection.css";

//Context
import { NavbarContext } from "../../context/NavbarContext";

const PropDevsSection = () => {
  const { propDevs } = useContext(NavbarContext);

  return (
    <section
      ref={propDevs}
      className="prop-devs-section-container"
      id="property"
    >
      <img
        className="prop-devs-bg"
        src="https://pagina-otacc.s3.amazonaws.com/pages/otacc-page/assets/home-bgs/home-bg-1.webp"
        alt="Imagén de fondo de la sección de proyectos inmobiliarios."
      />
      <div className="btn-container">
        <Link
          to="/vivienda"
          className="btn-section"
          title="Desarrollos Inmobiliarios"
        >
          Ver nuestros proyectos
        </Link>
      </div>
    </section>
  );
};

export default PropDevsSection;

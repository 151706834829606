import React, { useEffect } from "react";

//Styles
import "./Counter.css";

const Counter = ({ info }) => {
  useEffect(() => {
    const elements = document.querySelectorAll(".total-num");
    const speed = 2000;
    elements.forEach((element) => {
      const animate = async() => {
        const value = +element.title;
        const data = +element.innerText;

        const time = value / speed;
        if (data < value) {
          element.innerText = Math.ceil(data + time);
          setTimeout(animate, 1);
        } else {
          element.innerText = value;
          setTimeout(() => {
            element.textContent = value.toLocaleString('en');
          }, 1)
        }
      };
       animate();
      
    });
  }, []);

  return (
    <div className="counter-container">
      <div className={`nums-container ${info.colorTitle}`}>
        <h2
          className={`signCounter ${
            info.signPos === "left" ? "show-sign" : "hide-sign"
          }`}
        >
          {info.sign}
        </h2>
        <h2 className="total-num" title={info.value}>
          0
        </h2>
        <h2
          style={info.complement ? { display: "unset" } : { display: "none" }}
          className={info.hadSpace && "add-space"}
        >
          {info.complementValue}
        </h2>
        <h2
          className={`sign-counter ${
            info.signPos === "right" ? "show-sign" : "hide-sign"
          }`}
        >
          {info.sign}
        </h2>
      </div>
      <div className={`counter-subtitle ${info.colorTitle}`}>
        {info.exp && `${info.expComp}`}
        {info.exp && <sup>{info.expValue}</sup>}
        {info.subtitleFirst}
      </div>
    </div>
  );
};

export default Counter;

import React from 'react'

//Component
import NavbarPropDevsSection from '../../components/NavbarPropDevsSection/NavbarPropDevsSection';
import BodyPropDevs from '../../components/BodyPropDevs/BodyPropDevs';
import FooterPropDevs from '../../components/FooterPropDevs/FooterPropDevs';

const PropDevsScreen = () => {
  return (
    <>
      <NavbarPropDevsSection/>
      <BodyPropDevs />
      <FooterPropDevs />
    </>
  )
}

export default PropDevsScreen
import React from "react";

//Components
import NavbarPropDevsSection from "../../components/NavbarPropDevsSection/NavbarPropDevsSection";
import FooterPropDevs from "../../components/FooterPropDevs/FooterPropDevs";
import JobOfferBody from "../../components/JobOfferBody/JobOfferBody";

const JobOfferScreen = () => {
  return (
    <>
      <NavbarPropDevsSection />
      <JobOfferBody />
      <FooterPropDevs />
    </>
  );
};

export default JobOfferScreen;

import React from "react";

//Style
import "./InfoOilAndGasSection.css";

const InfoOilAndGasSection = ({ info }) => {
  return (
    <section className="info-oag-section">
      <div className="title-container">
        <span className="deco-title"></span>
        <h1 className="oag-title">Oil & Gas</h1>
        <img
          style={{ width: "2.5rem" }}
          className="title-icon"
          src="https://pagina-otacc.s3.amazonaws.com/pages/otacc-page/assets/icons/title-icon-1.webp"
          alt=""
        />
      </div>
      <div className="info-oag-supercontainer">
        <div className="info-oag-container border-line">
          <div className="info-oag-txt-container">
            <p className="oag-subtitle">Construcción (EPC):</p>
            <ul>
              <li>
                Plantas de deshidratación de crudo y tratamiento derivados
              </li>
              <li>Plantas de tratamiento e inyección de agua</li>
              <li>Locaciones para pozos</li>
              <li>Líneas de transporte</li>
              <li>Tanques de almacenamiento</li>
              <li>Estaciones de bombeo de crudo y compresión de gas</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InfoOilAndGasSection;

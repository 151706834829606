import React from "react";

//Components
import NavbarPropDevsSection from "../../components/NavbarPropDevsSection/NavbarPropDevsSection";
import FooterPropDevs from "../../components/FooterPropDevs/FooterPropDevs";
import JobOfferPageBody from "../../components/JobOfferPageBody/JobOfferPageBody";

const JobOfferPageScreen = () => {
  return (
    <>
      <NavbarPropDevsSection />
      <JobOfferPageBody />
      <FooterPropDevs />
    </>
  );
};

export default JobOfferPageScreen;

import React, {useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';

//Style
import './BodyEnergy.css';

//Data
import data from '../../json/projectsEneSection.json';
import dataCounter from '../../json/infoEnergyCounter.json';
import dataClientsEne from '../../json/clientsEnergy.json';

//Components
import PDCounterSection from '../PDCounterSection/PDCounterSection';
import PDProjectSection from '../PDProjectsSection/PDProjectSection';
import PDClientsSlide from '../PDClientsSlide/PDClientsSlide';
import InfoEnergySection from '../InfoEnergySection/InfoEnergySection';

const BodyEnergy = () => {

    const location = useLocation();
    
    const useWindowWide = () => {
        const [width, setWidth] = useState(0);
    
        useEffect(() => {
          function handleResize() {
            setWidth(window.innerWidth);
          }
    
          window.addEventListener("resize", handleResize);
    
          handleResize();
    
          return () => {
            window.removeEventListener("resize", handleResize);
          };
        }, [setWidth]);
    
        return width;
      };
    
      const wide = useWindowWide(767);

  return (
    <>
        <PDCounterSection location={location.pathname} video={true} />
        <div className="bg-pg"></div>
        <InfoEnergySection info={dataCounter.data} />
        <PDProjectSection location={location.pathname} projects={data} />
        <PDClientsSlide wide={wide} clients={dataClientsEne} />
    </>
  )
}

export default BodyEnergy
import React from 'react'

//Components
import NavbarPropDevsSection from '../../components/NavbarPropDevsSection/NavbarPropDevsSection'
import FooterPropDevs from '../../components/FooterPropDevs/FooterPropDevs'
import BodyInfraestructure from '../../components/BodyInfraestructure/BodyInfraestructure'

const RoadScreen = () => {
  return (
    <>
      <NavbarPropDevsSection />
      <BodyInfraestructure />
      <FooterPropDevs />
    </>
  )
}

export default RoadScreen
import React from "react";

//Components
import NavbarPropDevsSection from "../../components/NavbarPropDevsSection/NavbarPropDevsSection";
import FooterPropDevs from "../../components/FooterPropDevs/FooterPropDevs";
import BodyFoundation from "../../components/BodyFoundation/BodyFoundation";

const FoundationScreen = () => {
  return (
    <>
      <NavbarPropDevsSection />
      <BodyFoundation />
      <FooterPropDevs />
    </>
  );
};

export default FoundationScreen;

import React, { useContext } from "react";
import { Link } from "react-router-dom";

//Styles
import "./EnergySection.css";

//Context
import { NavbarContext } from "../../context/NavbarContext";

const EnergySection = () => {

  const {energy} = useContext(NavbarContext);

  return (
    <section ref={energy} className="ene-section-container" id="energy">
      <img
        className="ene-bg"
        src="https://pagina-otacc.s3.amazonaws.com/pages/otacc-page/assets/home-bgs/home-bg-2.webp"
        alt="Imagen de fondo de la sección de proyectos energéticos."
      />
      <div className="ene-btn-container">
        <Link to="/renovables" className="ene-btn" title="Energía">
          Ver nuestros proyectos
        </Link>
      </div>
    </section>
  );
};

export default EnergySection;

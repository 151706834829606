import React, { useContext, useState } from "react";

//Context
import { GridGalleryContext } from "../../context/GridGalleryContext";

//Style
import "./GridGallery.css";

const GridGallery = ({ wide, data }) => {
  const [img, setImg] = useState(data.data[0].src || "");

  const { setOpenView } = useContext(GridGalleryContext);

  return (
    <div className="gallery-container">
      <div className="img-container">
        <img src={img} alt="" className="gallery-img" />
      </div>
      <div className="thumbs-container">
        {data.data.map((item, i) => {
          if (wide <= 1023) {
            if (i > 1) {
              return (
                <div style={{ display: "none" }} key={i} className="thumb">
                  <img
                    className="thumb-img"
                    onClick={(e) => setImg(e.target.src)}
                    src={item.src}
                    alt={item.alt}
                  />
                </div>
              );
            } else {
              return (
                <div key={i} className={`thumb ${i === 1 && "last"}`}>
                  {i === 1 && (
                    <div className="more-btn-container">
                      <p className="img-total">+ {data.data.length - 1}</p>
                      <button
                        onClick={() => setOpenView(true)}
                        className="more-btn"
                      >
                        Ver más
                      </button>
                    </div>
                  )}
                  <img
                    className="thumb-img"
                    onClick={(e) => setImg(e.target.src)}
                    src={item.src}
                    alt={item.alt}
                  />
                </div>
              );
            }
          }
          if (i > 3) {
            return (
              <div style={{ display: "none" }} key={i} className="thumb">
                <img
                  className="thumb-img"
                  onClick={(e) => setImg(e.target.src)}
                  src={item.src}
                  alt={item.alt}
                />
              </div>
            );
          } else {
            return (
              <div key={i} className={`thumb ${i === 3 && "last"}`}>
                {i === 3 && (
                  <div className="more-btn-container">
                    <p className="img-total">+ {data.data.length - 3}</p>
                    <button
                      onClick={() => setOpenView(true)}
                      className="more-btn"
                    >
                      Ver más
                    </button>
                  </div>
                )}
                <img
                  className="thumb-img"
                  onClick={(e) => setImg(e.target.src)}
                  src={item.src}
                  alt={item.alt}
                />
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

export default GridGallery;

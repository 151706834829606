import React from "react";
import { Link } from "react-router-dom";

//Data
import data from "../../json/socialMedia.json";

//Styles
import "./FooterPropDevs.css";

const FooterPropDevs = () => {
  return (
    <footer className="footer-container">
      <div className="footer-info-container">
        <div className="footer-about-us">
          <h3>Algo sobre nosotros</h3>
          <p>
            Otacc es una empresa dedicada a la ingeniería y construcción de
            obras civiles, eléctricas, mecánicas y de instrumentación.
          </p>
        </div>
        <div className="social-media-footer">
          <h3>Síguenos en nuestras redes sociales</h3>
          <div className="social-media-container">
            {data.data.map((sm, i) => {
              return (
                <a
                  key={i}
                  className="anchor-sm"
                  href={sm.href}
                  title={sm.title}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="social-media"
                    onMouseOver={(e) => (e.target.src = sm.hover)}
                    onMouseLeave={(e) => (e.target.src = sm.src)}
                    src={sm.src}
                    alt={sm.alt}
                  />
                </a>
              );
            })}
          </div>
        </div>
      </div>
      <div className="footer-info-container">
        <h3>Contáctenos</h3>
        <p>Calle 49 No. 27A - 34 Bucaramanga - Colombia</p>
        <p>PBX: +57 (7) 6435677</p>
        <p>FAX: +57 (7) 6472253</p>
        <p>Clientes: clientes@otacc.com</p>
      </div>
      <div className="footer-info-container">
        <h3>Información útil</h3>
        <a
          className="doc-anchor"
          href="https://pagina-otacc.s3.amazonaws.com/pages/otacc-page/docs/FI-067+POLITICA+DE+TRATAMIENTO+DE+LA+INFORMACI%C3%93N+OTACC+S.A..pdf"
          target="_blank"
          title="Politica de Protección de Datos Personales"
          rel="noreferrer"
        >
          Política de Protección de Datos Personales
        </a>
        <a
          className="doc-anchor"
          href="/ofertas-de-empleo"
          title="Ofertas de empleo"
        >
          Ofertas de Empleo
        </a>
      </div>
      <div className="footer-info-container">
        <h3>Fundación OTACC S.A.</h3>
        <div className="foundation-btn-container">
          <Link
            className="anchor-foundation"
            to="/fundacion-otacc"
            title="Fundación OTACC"
          >
            <div className="text-btn-container">Conoce Nuestra Fundación</div>
            <div className="separation-line-btn"></div>
            <div className="icon-btn-container">
              <img
                className="btn-icon"
                src="https://pagina-otacc.s3.amazonaws.com/pages/otacc-page/assets/icons/btn-foundation-icon.webp"
                alt=""
              />
            </div>
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default FooterPropDevs;

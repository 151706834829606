import React, { useState } from "react";

//Components
import NavbarPropDevsSection from "../../components/NavbarPropDevsSection/NavbarPropDevsSection";
import FooterPropDevs from "../../components/FooterPropDevs/FooterPropDevs";
import BodyEnergy from "../../components/BodyEnergy/BodyEnergy";
import GalleryEnergy from "../../components/GalleryEnergy/GalleryEnergy";

//Context
import { GalleryContext } from "../../context/GalleryContext";

const EnergyScreen = () => {

  const [imgs, setImgs] = useState([]);

  const [openGallery, setOpenGallery] = useState(false);

  return (
    <>
      <GalleryContext.Provider value={{imgs, setImgs, openGallery, setOpenGallery}}>
        <GalleryEnergy />
        <NavbarPropDevsSection />
        <BodyEnergy />
        <FooterPropDevs />
      </GalleryContext.Provider>
    </>
  );
};

export default EnergyScreen;

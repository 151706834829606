import React from "react";

//Style
import "./InfoInfraestructureSection.css";

const InfoInfraestructureSection = ({ info }) => {
  return (
    <section className="info-infra-section">
      <div className="title-container">
        <span className="deco-title"></span>
        <h1 className="infra-title">Infraestructura</h1>
        <img
          style={{ width: "2.5rem" }}
          className="title-icon"
          src="https://pagina-otacc.s3.amazonaws.com/pages/otacc-page/assets/icons/title-icon-2.webp"
          alt=""
        />
      </div>
      <div className="info-infra-supercontainer">
        <div className="info-infra-container border-line">
          <div className="info-infra-txt-container">
            <p className="infra-subtitle">
              Construcción y proyectos llave en mano:
            </p>
            <ul>
              <li>Puentes e intercambiadores</li>
              <li>Vias</li>
              <li>Aeropuertos</li>
              <li>Instituciones educativas</li>
              <li>Instituciones de salud</li>
              <li>Plantas industriales</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InfoInfraestructureSection;

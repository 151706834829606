import React from 'react';

//Styles
import './Body.css';

//Components
import PropDevsSection from '../PropDevsSection/PropDevsSection'
import EnergySection from '../EnergySection/EnergySection'
import RoadSection from '../RoadSection/RoadSection';
import IndustrialSection from '../IndustrialSection/IndustrialSection';

const Body = () => {
  return (
    <div className='body-container'>
        <PropDevsSection />
        <IndustrialSection />
        <EnergySection />
        <RoadSection />
    </div>
  )
}

export default Body
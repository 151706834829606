import React from "react";
import { useInView } from "react-intersection-observer";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

//Styles
import "./App.css";

//Components
import PropDevsScreen from "./pages/PropDevsScreen/PropDevsScreen";
import Home from "./pages/Home/Home";
import EnergyScreen from "./pages/EnergyScreen/EnergyScreen";
import RoadScreen from "./pages/RoadScreen/RoadScreen";
import IndustrialScreen from "./pages/IndustrialScreen/IndustrialScreen";
import NotFoundScreen from "./pages/NotFoundScreen/NotFoundScreen";
import AboutUsScreen from "./pages/AboutUsScreen/AboutUsScreen";
import FoundationScreen from "./pages/FoundationScreen/FoundationScreen";

//Context
import { NavbarContext } from "./context/NavbarContext";
import JobOfferPageScreen from "./pages/JobOfferPageScreen/JobOfferPageScreen";
import JobOfferScreen from "./pages/JobOfferScreen/JobOfferScreen";

function App() {
  const { ref: propDevs, inView: propDevsInView } = useInView({
    rootMargin: "-20px 0px -20px 0px",
  });
  const { ref: energy, inView: energyInView } = useInView({
    rootMargin: "-20px 0px -20px 0px",
  });
  const { ref: road, inView: roadInView } = useInView({
    rootMargin: "-20px 0px -20px 0px",
  });
  const { ref: industrial, inView: industrialInView } = useInView({
    rootMargin: "-20px 0px -20px 0px",
  });

  return (
    <NavbarContext.Provider
      value={{
        propDevs,
        energy,
        road,
        industrial,
        propDevsInView,
        energyInView,
        roadInView,
        industrialInView,
      }}
    >
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/vivienda" element={<PropDevsScreen />} />
          <Route exact path="/oil-and-gas" element={<IndustrialScreen />} />
          <Route exact path="/renovables" element={<EnergyScreen />} />
          <Route exact path="/infraestructura" element={<RoadScreen />} />
          <Route exact path="/nosotros" element={<AboutUsScreen />} />
          <Route exact path="/fundacion-otacc" element={<FoundationScreen />} />
          <Route exact path="/ofertas-de-empleo" element={<JobOfferScreen />} />
          <Route
            exact
            path="/ofertas-de-empleo/:name"
            element={<JobOfferPageScreen />}
          />
          <Route path="*" element={<NotFoundScreen />} />
        </Routes>
      </Router>
    </NavbarContext.Provider>
  );
}

export default App;

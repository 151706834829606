import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

//Data
import dataProjectsOtacc from '../../json/infoAboutUs/projectsOtaccGallery.json';

//Components
import PDCounterSection from "../PDCounterSection/PDCounterSection";
import InfoAboutUsSection from "../InfoAboutUsSection/InfoAboutUsSection";
import AboutUsGridGallerySection from "../AboutUsGridGallerySection/AboutUsGridGallerySection";
import GoalsAboutUsSection from "../GoalsAboutUsSection/GoalsAboutUsSection";

const BodyAboutUs = () => {
  const location = useLocation();

  const useWindowWide = () => {
    const [width, setWidth] = useState(0);

    useEffect(() => {
      function handleResize() {
        setWidth(window.innerWidth);
      }

      window.addEventListener("resize", handleResize);

      handleResize();

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, [setWidth]);

    return width;
  };

  const wide = useWindowWide(767);

  return (
    <>
      <PDCounterSection location={location.pathname} video={false} />
      <InfoAboutUsSection />
      <AboutUsGridGallerySection wide={wide} data={dataProjectsOtacc} />
      <GoalsAboutUsSection />
    </>
  );
};

export default BodyAboutUs;

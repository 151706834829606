import React,{useRef, useState, useEffect} from "react";
import Carousel from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";

//Style
import './AboutUsGridGallerySection.css';

//Context
import { GridGalleryContext } from "../../context/GridGalleryContext";

//Components
import GridGallery from "../GridGallery/GridGallery";

const AboutUsGridGallerySection = ({wide, data}) => {
  const [openView, setOpenView] = useState(false);
  const gallery = useRef();

  useEffect(() => {}, [openView]);

  return (
    <section className="grid-au-gallery-section">
        <div className="grid-title-container">
          <span className="deco-title"></span>
            <h1>PROYECTOS RECIENTES</h1>
        </div>
      <GridGalleryContext.Provider value={{ setOpenView }}>
        {openView && (
          <>
            <div className="fs-gallery-container">
              <div
                className="reset-value-gallery"
                onClick={() => setOpenView(false)}
              ></div>
              <Carousel
                ref={gallery}
                images={data.data}
                isMaximized="true"
                hasIndexBoard="false"
                hasMediaButton="false"
                index={3}
              />
            </div>
          </>
        )}
        <GridGallery open={openView} wide={wide} data={data} />
        <div className="carousel-mobile-hor">
          <Carousel
            images={data.data}
            style={{
              height: "100%",
              width: "100%",
              backgroundColor: "transparent",
            }}
            isAutoPlaying={true}
            hasDotButtons={"bottom"}
            hasLeftButton={false}
            hasRightButton={false}
            hasMediaButton={false}
            hasIndexBoard={false}
            hasThumbnails={false}
            hasSizeButton={"topRight"}
            transitionSpeed={0.1}
            hasSizeButtonAtMax={"bottomRight"}
            hasThumbnailsAtMax={true}
            hasLeftButtonAtMax={"centerLeft"}
            hasRightButtonAtMax={"centerRight"}
          />
        </div>
      </GridGalleryContext.Provider>
    </section>
  );
};

export default AboutUsGridGallerySection;

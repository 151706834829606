import React, { useContext, useState, useRef } from "react";

//Style
import "./GalleryEnergy.css";

//Context
import { GalleryContext } from "../../context/GalleryContext";

const GalleryEnergy = () => {
  const { imgs, openGallery, setOpenGallery } = useContext(GalleryContext);

  const [index, setIndex] = useState(0);

  const [startX, setStartX] = useState(null);

  const [endX, setEndX] = useState(null);

  const containerRef = useRef(null);

  const totalImgs = imgs.length;

  const handleClose = () => {
    setIndex(0);
    setOpenGallery(false);
  };

  const handleBtnGallery = (e) => {
  
    if (e.target.title === "prev") {
      index === 0 ? setIndex(totalImgs - 1) : setIndex(index - 1);
    }

    if (e.target.title === "next") {
      index === totalImgs - 1 ? setIndex(0) : setIndex(index + 1);
    }
  };

  const handleTouchEnd = () => {
    if (startX !== null && endX !== null) {
      const deltaX = endX - startX;

      if (deltaX > 0) {
        index === 0 ? setIndex(totalImgs - 1) : setIndex(index - 1)
      } else if (deltaX < 0) {
        index === totalImgs - 1 ? setIndex(0) : setIndex(index + 1)
      }

      setStartX(null);
      setEndX(null);
    }
  };

  const handleTouchMove = (e) => {
    setEndX(e.touches[0].clientX);
  };

  const handleTouchStart = (e) => {
    setStartX(e.touches[0].clientX);
  };

  return (
    <div className={`otacc-gallery-container ${openGallery ? "open" : ""}`}>
      <div className="close-btn-container">
        <div className="close-icon" onClick={handleClose}>
          X
        </div>
      </div>
      <div className="imgs-container">
        <div
          className="icon-gallery prev-btn"
          title="prev"
          onClick={(e) => handleBtnGallery(e)}
        >
          {"<"}
        </div>
        <div className="img-container">
          <img
            ref={containerRef}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
            onTouchMove={handleTouchMove}
            className="img-gallery"
            src={imgs.length > 0 ? imgs[index].src : undefined}
            alt=""
          />
        </div>
        <div
          className="icon-gallery nxt-btn"
          title="next"
          onClick={(e) => handleBtnGallery(e)}
        >
          {">"}
        </div>
      </div>
    </div>
  );
};

export default GalleryEnergy;

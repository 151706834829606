import React from "react";
import NavbarPropDevsSection from "../../components/NavbarPropDevsSection/NavbarPropDevsSection";
import FooterPropDevs from "../../components/FooterPropDevs/FooterPropDevs";
import BodyNotFoundPage from "../../components/BodyNotFoundPage/BodyNotFoundPage";

const NotFoundScreen = () => {
  return (
    <>
      <NavbarPropDevsSection />
      <BodyNotFoundPage />
      <FooterPropDevs />
    </>
  );
};

export default NotFoundScreen;

import React from "react";
import { useLocation } from "react-router-dom";

//Components
import PDCounterSection from "../PDCounterSection/PDCounterSection";
import DocsFoundationSection from "../DocsFoundationSection/DocsFoundationSection";

const BodyFoundation = () => {
  const location = useLocation();
  return (
    <>
      <PDCounterSection location={location.pathname} video={false} />
      <DocsFoundationSection />
    </>
  );
};

export default BodyFoundation;

import React from "react";

//Style
import "./DocsFoundationSection.css";

//Data
import dataDocs from "../../json/foundationOtacc/docsByYear.json";

//Components
import DocsByYearComponent from "../DocsByYearComponent/DocsByYearComponent";

const DocsFoundationSection = () => {
  return (
    <section className="docs-section">
      <DocsByYearComponent docs={dataDocs.data[0]} />
      <hr className="separation-line-mb" />
      <div className="separation-line"></div>
      <DocsByYearComponent docs={dataDocs.data[1]} />
      <div className="separation-line"></div>
      <DocsByYearComponent docs={dataDocs.data[2]} />
    </section>
  );
};

export default DocsFoundationSection;

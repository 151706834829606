import React, { useState, useEffect } from "react";

//Data
import dataEne from "../../json/counterInfoEne.json";
import dataOilAndGas from "../../json/counterInfoOilAndGas.json";
import dataInfra from "../../json/counterInfoInfraestructure.json";
import dataAboutUs from "../../json/counterInfoAboutus.json";
import dataPropDevs from '../../json/counterInfoPD.json';

//Styles
import "./PDCounterSection.css";

//Components
import Counter from "../Counter/Counter";

const PDCounterSection = ({ location, video }) => {
  const [dataCounter, setDataCounter] = useState([]);

  useEffect(() => {
    if (location === "/oil-and-gas") {
      setDataCounter(dataOilAndGas.data);
    } else if (location === "/renovables") {
      setDataCounter(dataEne.data);
    } else if (location === "/infraestructura") {
      setDataCounter(dataInfra.data);
    } else if (location === "/nosotros") {
      setDataCounter(dataAboutUs.data);
    } else if (location === "/vivienda") {
      setDataCounter(dataPropDevs.data);
    }
  }, [location]);

  return (
    <section className="pd-counter-section">
      {video ? (
        <video className="pd-counter-video" autoPlay loop muted playsInline>
          <source
            src={
              location === "/renovables"
                ? "https://pagina-otacc.s3.amazonaws.com/pages/otacc-page/videos/home-energy.mp4"
                : location === "/oil-and-gas"
                ? "https://pagina-otacc.s3.amazonaws.com/pages/otacc-page/videos/home-oil-and-gas.mp4"
                : location === "/infraestructura" ?
                  "https://pagina-otacc.s3.amazonaws.com/pages/otacc-page/videos/home-infraestructure.MP4" : location === "/vivienda" && "https://pagina-otacc.s3.amazonaws.com/pages/otacc-page/videos/home-propdevs.mp4"
            }
            type="video/mp4"
          />
        </video>
      ) : (
        <img
          className="pd-counter-img"
          src={
            location === "/nosotros"
              ? "https://pagina-otacc.s3.amazonaws.com/pages/otacc-page/aboutus/banner/banner-aboutus.webp"
              : location === "/fundacion-otacc" ? "https://pagina-otacc.s3.amazonaws.com/pages/otacc-page/foundation/bgs/foundation-banner.webp" : ""
          }
          alt=""
        />
      )}
      <div className="pd-counters-container">
        {dataCounter.map((counter, i) => {
          return <Counter key={i} info={counter} />;
        })}
      </div>
    </section>
  );
};

export default PDCounterSection;

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

//Style
import './BodyInfraestructure.css';

//Data
import dataInfoSection from '../../json/infoInfraestructureSection.json';
import data from '../../json/projectsInfraSection.json';
import dataClients from '../../json/clientsInfra.json';

//Components
import PDCounterSection from "../PDCounterSection/PDCounterSection";
import InfoInfraestructureSection from "../InfoInfraestructureSection/InfoInfraestructureSection";
import PDProjectSection from "../PDProjectsSection/PDProjectSection";
import PDClientsSlide from "../PDClientsSlide/PDClientsSlide";

const BodyInfraestructure = () => {
  const location = useLocation();

  const useWindowWide = () => {
    const [width, setWidth] = useState(0);

    useEffect(() => {
      function handleResize() {
        setWidth(window.innerWidth);
      }

      window.addEventListener("resize", handleResize);

      handleResize();

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, [setWidth]);

    return width;
  };

  const wide = useWindowWide(767);
  return (
    <>
        <PDCounterSection location={location.pathname} video={true} />
        <div className="bg-pg"></div>
        <InfoInfraestructureSection info={dataInfoSection.data} />
        <PDProjectSection location={location.pathname} projects={data} />
        <PDClientsSlide wide={wide} clients={dataClients} />
    </>
  );
};

export default BodyInfraestructure;

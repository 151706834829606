import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

//Styles
import "./NavbarPropDevsSection.css";

const NavbarPropDevsSection = () => {
  const location = useLocation();

  const [viewMenu, setViewMenu] = useState(false);

  return (
    <nav className="nb-prop-devs-container">
      <Link to="/" className="nb-prop-devs-logo-container">
        <img
          src={
            location.pathname === "/vivienda"
              ? "https://pagina-otacc.s3.amazonaws.com/pages/otacc-page/assets/icons/logo-otacc.webp"
              : "https://pagina-otacc.s3.amazonaws.com/pages/otacc-page/assets/icons/logo-otacc-white.webp"
          }
          alt="logo de Otacc"
          className="nb-prop-devs-logo-img"
        />
      </Link>
      <div className="nb-prop-devs-menus-container-nm">
        <Link
          to="/vivienda"
          className={`nb-prop-devs-option ${
            location.pathname === "/vivienda" ? "current" : ""
          } ${location.pathname === "/vivienda" ? "text-darken" : ""}`}
        >
          Vivienda
        </Link>
        <Link
          to="/oil-and-gas"
          className={`nb-prop-devs-option ${
            location.pathname === "/oil-and-gas" ? "current" : ""
          } ${location.pathname === "/vivienda" ? "text-darken" : ""}`}
        >
          Oil & Gas
        </Link>
        <Link
          to="/renovables"
          className={`nb-prop-devs-option ${
            location.pathname === "/renovables" ? "current" : ""
          } ${location.pathname === "/vivienda" ? "text-darken" : ""}`}
        >
          Energías Renovables
        </Link>
        <Link
          to="/infraestructura"
          className={`nb-prop-devs-option ${
            location.pathname === "/infraestructura" ? "current" : ""
          } ${location.pathname === "/vivienda" ? "text-darken" : ""}`}
        >
          Infraestructura
        </Link>
        <Link
          to="/nosotros"
          className={`nb-prop-devs-option ${
            location.pathname === "/nosotros" ? "current" : ""
          } ${location.pathname === "/vivienda" ? "text-darken" : ""}`}
        >
          Nosotros
        </Link>
      </div>
      <div className="nb-prop-devs-mobile-container">
        <img
          onClick={() => setViewMenu(!viewMenu)}
          src="https://pagina-otacc.s3.amazonaws.com/pages/otacc-page/assets/ham-menu-icon.webp"
          alt=""
          className="ham-icon"
        />
        <div
          className={`mb-prop-devs-menus-container ${
            viewMenu ? "" : "closed-menu"
          }`}
        >
          <Link
            to="/vivienda"
            className="nb-prop-devs-option"
            onClick={() => setViewMenu(false)}
          >
            Vivienda
          </Link>
          <Link
            to="/oil-and-gas"
            className="nb-prop-devs-option"
            onClick={() => setViewMenu(false)}
          >
            Oil & Gas
          </Link>
          <Link
            to="/renovables"
            className="nb-prop-devs-option"
            onClick={() => setViewMenu(false)}
          >
            Energías Renovables
          </Link>
          <Link
            to="/infraestructura"
            className="nb-prop-devs-option"
            onClick={() => setViewMenu(false)}
          >
            Infraestructura
          </Link>
          <Link
            to="/nosotros"
            className="nb-prop-devs-option"
            onClick={() => setViewMenu(false)}
          >
            Nosotros
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default NavbarPropDevsSection;

import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

//Data
import data from "../../json/projectsPDSection.json";
import dataImgsProjects from "../../json/PDImgsProjects.json";

//Components
import PDProjectSection from "../PDProjectsSection/PDProjectSection";
import PDGridGallerySection from "../PDGridGallerySection/PDGridGallerySection";
import PDCounterSection from "../PDCounterSection/PDCounterSection";

const BodyPropDevs = () => {
  const location = useLocation();

  const useWindowWide = () => {
    const [width, setWidth] = useState(0);

    useEffect(() => {
      function handleResize() {
        setWidth(window.innerWidth);
      }

      window.addEventListener("resize", handleResize);

      handleResize();

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, [setWidth]);

    return width;
  };

  const wide = useWindowWide(767);

  return (
    <>
      <PDCounterSection location={location.pathname} video={true} />
      <PDProjectSection location={location.pathname} projects={data} />
      <PDGridGallerySection wide={wide} data={dataImgsProjects} />
    </>
  );
};

export default BodyPropDevs;

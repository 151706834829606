// Libraries

import { createContext } from 'react';

// Functions

/**
 * Creates the context.
 */

export const GridGalleryContext = createContext();
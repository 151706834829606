import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

//Data
import jobOfferPageData from "../../json/JobOfferPageData/JobOfferPagesData.json";

//Components
import JobOfferPageBannerSection from "../JobOfferPageBannerSection/JobOfferPageBannerSection";
import JobOfferInfoSection from "../JobOfferInfoSection/JobOfferInfoSection";
import JobOfferFormComponent from "../JobOfferFormComponent/JobOfferFormComponent";

const JobOfferPageBody = () => {
  const [jobOfferPage, setJobOfferPage] = useState({});

  const { name } = useParams();

  useEffect(() => {
    const selectedJobOffer = jobOfferPageData.data.filter(
      (job) => job.name === name
    );
    setJobOfferPage(selectedJobOffer[0]);
  }, [name]);

  return (
    <>
      {jobOfferPage && (
        <>
          <JobOfferPageBannerSection
            bannerData={jobOfferPage.banner_section_data}
          />
          <JobOfferInfoSection
            sectionData={jobOfferPage.job_offer_info_section_data}
          />
          <JobOfferFormComponent offerName={name} />
        </>
      )}
    </>
  );
};

export default JobOfferPageBody;

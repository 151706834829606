import React from "react";
import Carousel from "react-grid-carousel";
import CarouselDos from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";

//Styles
import "./PDClientsSlide.css";

const PDClientsSlide = ({ wide, clients }) => {
  const elements = [];

  clients.data.map((item, i) => {
    return elements.push(
      <img
        key={i}
        className="client-logo"
        src={item.src}
        title={item.title}
        alt={item.title}
        style={{ width: "10rem" }}
      />
    );
  });

  return (
    <section className="clients-section">
      <div className="clients-title-container">
        <h2 className="clients-title">Nuestros Clientes</h2>
        <hr style={{ width: "50%" }} />
      </div>
      <div className="clients-slide-container">
        <div className="carousel-mobile">
          <CarouselDos
            children={elements}
            style={{
              height: "100%",
              width: "100%",
              backgroundColor: "transparent",
            }}
            isAutoPlaying={true}
            hasDotButtons={false}
            hasLeftButton={false}
            hasRightButton={false}
            hasMediaButton={false}
            hasIndexBoard={false}
            hasThumbnails={false}
            hasSizeButton={false}
            transitionSpeed={0.1}
          />
        </div>
        <div
          className="carousel-no-mobile-clients"
          style={
            clients.data.length > 8 ? { display: "unset" } : { display: "flex" }
          }
        >
          {clients.data.length <= 8 && (
            <div className="clients-minor-eight-container">
              {clients.data.map((client, i) => {
                return (
                  <img
                    className="client-logo"
                    key={i}
                    src={client.src}
                    alt={client.title}
                    title={client.title}
                  />
                );
              })}
            </div>
          )}
          {clients.data.length > 8 && (
            <Carousel
              cols={
                wide >= 480 && wide < 768
                  ? 2
                  : wide >= 768 && wide < 1024
                  ? 4
                  : wide >= 1920
                  ? 9
                  : 5
              }
              rows={1}
              loop
              autoplay={3000}
              showDots={false}
              mobileBreakpoint={479}
              hideArrow={true}
            >
              {clients.data.map((client, i) => {
                return (
                  <Carousel.Item key={i}>
                    <img
                      className="client-logo"
                      src={client.src}
                      title={client.title}
                      alt={client.title}
                    />
                  </Carousel.Item>
                );
              })}
            </Carousel>
          )}
        </div>
      </div>
    </section>
  );
};

export default PDClientsSlide;

import React from "react";

//Style
import "./DocsByYearComponent.css";

const DocsByYearComponent = ({ docs }) => {
  return (
    <div className="docs-by-year-container">
      <p className="title-docs">{docs.title}:</p>
      <ul className="list-container">
        {docs.docs.map((doc, i) => {
          return (
            <li key={i}>
              <a
                className="anchor-doc"
                href={doc.document}
                target="_blank"
                title={doc.titleDoc}
                rel="noreferrer"
              >
                {doc.titleDoc}
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default DocsByYearComponent;
